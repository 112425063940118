import React from "react";
import NotFound from "./Components/Common/404";

const LandingPage = React.lazy(() => import("./Pages/LandingPage"));

const routes = [
  {
    path: "*",
    component: NotFound,
    isProtected: false,
  },
  {
    path: "/",
    component: LandingPage,
    isProtected: false,
  },
];

export default routes;
