import React from "react";
import smwr from "../../../Assets/Svg/smwr.svg";
import { useNavigate } from "react-router-dom";

const SomethingWentWrong = () => {
  const navigate = useNavigate();
  const handleReload = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img src={smwr} alt="" style={{ height: "400px", marginTop: "5%" }} />
        <h1 className="mt-3">Something went wrong</h1>
        <p className="mt-2">
          Please go to
          <span
            className="mx-2"
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleReload(-1)}
          >
            Back page
          </span>{" "}
          or{" "}
          <span
            className="mx-2"
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleReload("/")}
          >
            Home page
          </span>
        </p>
      </div>
    </>
  );
};

export default SomethingWentWrong;
