import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = false;

  return token ? <Component {...rest} /> : <Navigate to="/" replace={true} />;
};

export default ProtectedRoute;
