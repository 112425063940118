import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRouter";
import routes from "./routes.js";
import ErrorBoundary from "./ErrorBoundary";
import PageLoader from "./Components/Common/PageLoader";

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <ErrorBoundary>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.isProtected ? (
                      <ProtectedRoute component={route.component} />
                    ) : (
                      <route.component />
                    )
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
